.App {
  text-align: center;
}


.App-content {
  background-color:#ffffff;
  min-height: 100vh;
  max-width: 1220px;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: rgb(70, 69, 69);
}

.App-link {
  color: #607d8b;
}
nav {
  background-color: #607d8b;
  justify-content: end;
  display:flex;
  padding-right: 40px;
  z-index: 100;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container{
  /* width: 300px; */
  height: 400px;
}
#mapid { height:250px; }